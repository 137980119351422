import React, { Component, Fragment } from 'react';
import ButtonCustom from '../components/mini/ButtonCustom';
import { Form, Row, Col, Container } from 'react-bootstrap';
import validate from '../pages/validate';
import {RootContext} from '../Home';
import cx from 'classnames';
import styleRegis from '../pages/RegistrationPage.module.scss';
import { Formik } from 'formik';
import CustomInput from '../components/mini/CustomInput';
import ExampleSelfie from '../assets/example_selfie.jpg';
// import { MobileView, BrowserView } from "react-device-detect";
import AlertModal from '../components/mini/AlertModal';

export default class CardInformationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideNpwpReasonElement: false,
            showModal: false
        }

        this.setShowModal = this.setShowModal.bind(this);
    }

    setShowModal(status) {
        this.setState({showModal: status});
    }

	render() {
		return (
            <RootContext.Consumer>
                {
                    store => {

                        let npwpReasonBlockElement = false;
                        if (store.state.registration.npwp_number !== "") {
                          npwpReasonBlockElement = true;
                        } else if (store.state.registration.npwp_number !== "" && this.state.hideNpwpReasonElement === false) {
                          npwpReasonBlockElement = false;
                        } else {
                          npwpReasonBlockElement = this.state.hideNpwpReasonElement;
                        }

                        return (
                            <Formik
                                validate={(values) => validate(values, '#card-information')} 
                                onSubmit={(values, actions) => {
                                    if(values.npwp_number === "" && values.no_npwp_reason === "") {
                                        this.setState({showModal: true});
                                        actions.setSubmitting(false);
                                    }

                                    if (values.npwp_number !== "" || values.no_npwp_reason !== "") {
                                      store.dispatch({ type: "handleSubmit", field: "registration_card_information", values: values });
                                    }
                                }}
                                initialValues={{    
                                    ktp_number: store.state.registration.ktp_number,
                                    ktp_expired_date: 'lifetime',
                                    ktp_file: store.state.registration.ktp_file,
                                    npwp_number: store.state.registration.npwp_number,
                                    npwp_registration_date: store.state.registration.npwp_registration_date,
                                    npwp_file: store.state.registration.npwp_file,
                                    no_npwp_reason: store.state.registration.no_npwp_reason,
                                    passport_number: store.state.registration.passport_number,
                                    passport_file: store.state.registration.passport_file,
                                    passport_expired_date: store.state.registration.passport_expired_date,
                                    kitas: store.state.registration.kitas,
                                    kitas_file: store.state.registration.kitas_file,
                                    kitas_expired_date: store.state.registration.kitas_expired_date,
                                    self_photo_file: store.state.registration.self_photo_file,
                                }}
                                >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    isSubmitting,
                                    touched,
                                    isValid,
                                    errors,
                                    setFieldValue,
                                    setFieldTouched,
                                }) => (

                                <Fragment>
                                    <Form id="card-information" className="form" validated={errors ? false : true } noValidate onSubmit={handleSubmit}>
                                        {/*
                                        <Modal show={true}>
                                            <Modal.Body>Test Modal</Modal.Body>
                                        </Modal>
                                */}
                                        {
                                            (!store.state.citizenship.foreigner) ? 
                                            <Fragment>
                                            
                                            {
                                                /*
                                                <Form.Row>
                                                    <pre
                                                      className="col-lg-12"
                                                      style={{
                                                        background: '#f6f8fa',
                                                        fontSize: '.65rem',
                                                        padding: '.5rem',
                                                        textAlign: 'left',
                                                      }}
                                                    >
                                                      <strong>props</strong> ={' '}
                                                      {JSON.stringify(values, null, 2)}
                                                      <br/>
                                                      <strong>errors</strong> ={' '}
                                                      {JSON.stringify(errors, null, 2)}
                                                      <br/>
                                                    </pre>
                                                </Form.Row>
                                                */
                                            }
                                                <Form.Row>
                                                    <CustomInput 
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'ktp_number'})}
                                                        required
                                                        readOnly
                                                        name="ktp_number"
                                                        type="text"
                                                        maxLength="16"
                                                        minLength="16"
                                                        pattern="^[0-9]*$"
                                                        value={values.ktp_number}
                                                        handleChange={handleChange}
                                                        isValid={touched.ktp_number && !errors.ktp_number}
                                                        isInvalid={!!errors.ktp_number}
                                                        error={ (typeof errors.ktp_number === 'object') ? store.dispatch({type:'translate', 'text': errors.ktp_number.type}).toString().replace('$param$',errors.ktp_number.param).replace('$param2$',errors.ktp_number.param2) : store.dispatch({type:'translate', 'text': errors.ktp_number})}
                                                    />
                                                    <CustomInput 
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'ktp_expired_date'})}
                                                        required
                                                        readOnly
                                                        name="ktp_expired_date"
                                                        type="text"
                                                        value={store.dispatch({type:'translate', 'text': values.ktp_expired_date})}
                                                        handleChange={handleChange}
                                                        isValid={touched.ktp_expired_date && !errors.ktp_expired_date}
                                                        isInvalid={!!errors.ktp_expired_date}
                                                        error={ (typeof errors.ktp_expired_date === 'object') ? store.dispatch({type:'translate', 'text': errors.ktp_expired_date.type}).toString().replace('$param$',errors.ktp_expired_date.param).replace('$param2$',errors.ktp_expired_date.param2) : store.dispatch({type:'translate', 'text': errors.ktp_expired_date})}
                                                    />
                                                </Form.Row>

                                                <Form.Row>
                                                    <CustomInput
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'ktp_file'})}
                                                        name="ktp_file"
                                                        type="file"
                                                        accept="image/jpg,image/png,image/jpeg"
                                                        capture
                                                        required={!store.state.registration.ktp_path && !values.ktp_file && !store.state.citizenship.foreigner ? true : false}
                                                        exists={store.state.registration.ktp_path}
                                                        handleChange={(file, imgTemp) => setFieldValue('ktp_file', file)}
                                                        isValid={touched.ktp_file && !errors.ktp_file}
                                                        isInvalid={!!errors.ktp_file}
                                                        error={ (typeof errors.ktp_file === 'object') ? store.dispatch({type:'translate', 'text': errors.ktp_file.type}).toString().replace('$param$',errors.ktp_file.param).replace('$param2$',errors.ktp_file.param2) : store.dispatch({type:'translate', 'text': errors.ktp_file})}
                                                    />
                                                    <CustomInput
                                                        col="6"
                                                        labelName={!store.state.citizenship.foreigner ? store.dispatch({type:'translate', 'text': 'photo_selfie_with_ktp'}) : store.dispatch({type:'translate', 'text': 'photo_selfie_with_kitas'})}
                                                        name="self_photo_file"
                                                        type="file"
                                                        accept="image/jpg,image/png,image/jpeg"
                                                        capture
                                                        required={!store.state.registration.self_photo_path && !values.self_photo_file ? true : false}
                                                        exists={store.state.registration.self_photo_path}
                                                        handleChange={(file) => setFieldValue('self_photo_file', file)}
                                                        isValid={touched.self_photo_file && !errors.self_photo_file}
                                                        isInvalid={!!errors.self_photo_file}
                                                        error={ (typeof errors.self_photo_file === 'object') ? store.dispatch({type:'translate', 'text': errors.self_photo_file.type}).toString().replace('$param$',errors.self_photo_file.param).replace('$param2$',errors.self_photo_file.param2) : store.dispatch({type:'translate', 'text': errors.self_photo_file})}
                                                        example={ExampleSelfie}
                                                        exampleInfoText={store.dispatch({type:'translate', 'text': 'example_info_text'})}
                                                        directSelfie={true}
                                                    />
                                                </Form.Row>

                                            </Fragment>
                                            : ''
                                        }
                                        {
                                            (store.state.citizenship.foreigner) ? 
                                            <Form.Row>
                                                <CustomInput 
                                                    col="4"
                                                    labelName={store.dispatch({type:'translate', 'text': 'kitas'})}
                                                    name="kitas"
                                                    type="text"
                                                    required
                                                    readOnly
                                                    value={values.kitas}
                                                    handleChange={handleChange}
                                                    isValid={touched.kitas && !errors.kitas}
                                                    isInvalid={!!errors.kitas}
                                                    error={ (typeof errors.kitas === 'object') ? store.dispatch({type:'translate', 'text': errors.kitas.type}).toString().replace('$param$',errors.kitas.param).replace('$param2$',errors.kitas.param2) : store.dispatch({type:'translate', 'text': errors.kitas})}
                                                />
                                                <CustomInput 
                                                    col="4"
                                                    labelName={store.dispatch({type:'translate', 'text': 'kitas_expired_date'})}
                                                    name="kitas_expired_date"
                                                    type="date"
                                                    required
                                                    dateType="expireddate"
                                                    value={values.kitas_expired_date}
                                                    handleChange={date => setFieldValue('kitas_expired_date', date)}
                                                    isValid={touched.kitas_expired_date && !errors.kitas_expired_date}
                                                    isInvalid={!!errors.kitas_expired_date}
                                                    error={ (typeof errors.kitas_expired_date === 'object') ? store.dispatch({type:'translate', 'text': errors.kitas_expired_date.type}).toString().replace('$param$',errors.kitas_expired_date.param).replace('$param2$',errors.kitas_expired_date.param2) : store.dispatch({type:'translate', 'text': errors.kitas_expired_date})}
                                                    placeholder="DD/MM/YYYY"
                                                />
                                                <CustomInput
                                                    col="4"
                                                    labelName={store.dispatch({type:'translate', 'text': 'kitas_file'})}
                                                    name="kitas_file"
                                                    type="file"
                                                    accept="image/jpg,image/png,image/jpeg"
                                                    capture
                                                    required={!store.state.registration.kitas_path && !values.kitas_file && store.state.citizenship.foreigner ? true : false}
                                                    exists={store.state.registration.kitas_path}
                                                    handleChange={(file, imgTemp) => setFieldValue('kitas_file', file)}
                                                    isValid={touched.kitas_file && !errors.kitas_file}
                                                    isInvalid={!!errors.kitas_file}
                                                    error={ (typeof errors.kitas_file === 'object') ? store.dispatch({type:'translate', 'text': errors.kitas_file.type}).toString().replace('$param$',errors.kitas_file.param).replace('$param2$',errors.kitas_file.param2) : store.dispatch({type:'translate', 'text': errors.kitas_file})}
                                                />
                                            </Form.Row>
                                            : ''
                                        }
                                        <Fragment>
                                            <Container fluid={true}>
                                            <Row>
                                                <Col xs={12} md={6} className={styleRegis.blockSection}>
                                                    <CustomInput 
                                                        labelName={store.dispatch({type:'translate', 'text': 'npwp_number'})}
                                                        col="div"
                                                        name="npwp_number"
                                                        type="text"
                                                        maxLength="15"
                                                        minLength="15"
                                                        pattern="^[0-9]*$"
                                                        required={(values.npwp_file || store.state.registration.npwp_path || values.npwp_registration_date) ? true : false}
                                                        value={values.npwp_number}
                                                        handleChange={(event) => {

                                                            setFieldValue('npwp_number', event.target.value);

                                                            let npwpValue = event.target.value;
                                                            if(npwpValue !== "") {
                                                                setFieldValue('no_npwp_reason', "");
                                                                this.setState({hideNpwpReasonElement: true});
                                                            } else {
                                                                this.setState({hideNpwpReasonElement: false});
                                                            }
                                                        }}
                                                        isValid={touched.npwp_number && !errors.npwp_number}
                                                        isInvalid={!!errors.npwp_number}
                                                        error={ (typeof errors.npwp_number === 'object') ? store.dispatch({type:'translate', 'text': errors.npwp_number.type}).toString().replace('$param$',errors.npwp_number.param).replace('$param2$',errors.npwp_number.param2) : store.dispatch({type:'translate', 'text': errors.npwp_number})}
                                                    />
                                                    <CustomInput 
                                                        labelName={store.dispatch({type:'translate', 'text': 'npwp_registration_date'})}
                                                        col="div"
                                                        name="npwp_registration_date"
                                                        type="date"
                                                        required={(values.npwp_file || store.state.registration.npwp_path || values.npwp_number) ? true : false}
                                                        dateType="registerdate"
                                                        value={values.npwp_registration_date}
                                                        handleChange={date => setFieldValue('npwp_registration_date', date)}
                                                        isValid={touched.npwp_registration_date && !errors.npwp_registration_date}
                                                        isInvalid={!!errors.npwp_registration_date}
                                                        error={ (typeof errors.npwp_registration_date === 'object') ? store.dispatch({type:'translate', 'text': errors.npwp_registration_date.type}).toString().replace('$param$',errors.npwp_registration_date.param).replace('$param2$',errors.npwp_registration_date.param2) : store.dispatch({type:'translate', 'text': errors.npwp_registration_date})}
                                                        placeholder="DD/MM/YYYY"
                                                    />
                                                    <CustomInput
                                                        labelName={store.dispatch({type:'translate', 'text': 'npwp_file'})}
                                                        col="div"
                                                        name="npwp_file"
                                                        type="file"
                                                        accept="image/jpg,image/png,image/jpeg"
                                                        capture
                                                        exists={store.state.registration.npwp_path}
                                                        handleChange={(file) => setFieldValue('npwp_file', file)}
                                                        isValid={touched.npwp_file && !errors.npwp_file}
                                                        isInvalid={!!errors.npwp_file}
                                                        error={ (typeof errors.npwp_file === 'object') ? store.dispatch({type:'translate', 'text': errors.npwp_file.type}).toString().replace('$param$',errors.npwp_file.param).replace('$param2$',errors.npwp_file.param2) : store.dispatch({type:'translate', 'text': errors.npwp_file})}
                                                    />

                                                    <CustomInput
                                                    labelName="Alasan tidak mengisi data NPWP"
                                                    col="div"
                                                    name="no_npwp_reason"
                                                    type="select"
                                                    info="*Harus dipilih apabila anda tidak mengisi data NPWP. Apabila anda memiliki NPWP, silahkan isikan di kolom isian NPWP dan abaikan pilihan ini."  
                                                    hideElement={npwpReasonBlockElement}
                                                    option={[
                                                        {value: '1', label: "Belum memiliki/mengajukan NPWP"},
                                                        {value: '2', label: "Tidak diwajibkan memiliki NPWP sesuai dengan ketentuan undang-undang dan juga tidak memiliki BO (Beneficial Owner)"},
                                                        {value: '3', label: "Telah memiliki NPWP namun tidak dibawa / hilang / alasan lain dan nomor tidak ingat"}
                                                    ]}
                                                    onChange={(name, value) => {
                                                        setFieldValue(name, value);
                                                    }}
                                                    onBlur={setFieldTouched}
                                                    placeholder="Pilih salah satu"
                                                    value={values.no_npwp_reason}
                                                    gap={true}
                                                    />

                                                </Col>
                                                <Col xs={12} md={6} className={styleRegis.blockSection}>
                                                    <CustomInput 
                                                        col="div"
                                                        labelName={store.dispatch({type:'translate', 'text': 'passport_number'})}
                                                        name="passport_number"
                                                        type="text"
                                                        minLength="7"
                                                        maxLength="10"
                                                        pattern="^[A-Za-z1-9][A-Za-z0-9*$]+"
                                                        required={(store.state.citizenship.foreigner) ? true : (values.passport_file || store.state.registration.passport_path || values.passport_expired_date) ? true : false}
                                                        value={values.passport_number}
                                                        handleChange={handleChange}
                                                        isValid={touched.passport_number && !errors.passport_number}
                                                        isInvalid={!!errors.passport_number}
                                                        error={ (typeof errors.passport_number === 'object') ? store.dispatch({type:'translate', 'text': errors.passport_number.type}).toString().replace('$param$',errors.passport_number.param).replace('$param2$',errors.passport_number.param2) : store.dispatch({type:'translate', 'text': errors.passport_number})}
                                                    />
                                                    <CustomInput 
                                                        col="div"
                                                        labelName={store.dispatch({type:'translate', 'text': 'passport_expired_date'})}
                                                        name="passport_expired_date"
                                                        type="date"
                                                        dateType="expireddate"
                                                        required={(store.state.citizenship.foreigner) ? true : (values.passport_file || store.state.registration.passport_path || values.passport_number) ? true : false}
                                                        value={values.passport_expired_date}
                                                        handleChange={date => setFieldValue('passport_expired_date', date)}
                                                        isValid={touched.passport_expired_date && !errors.passport_expired_date}
                                                        isInvalid={!!errors.passport_expired_date}
                                                        error={ (typeof errors.passport_expired_date === 'object') ? store.dispatch({type:'translate', 'text': errors.passport_expired_date.type}).toString().replace('$param$',errors.passport_expired_date.param).replace('$param2$',errors.passport_expired_date.param2) : store.dispatch({type:'translate', 'text': errors.passport_expired_date})}
                                                        placeholder="DD/MM/YYYY"
                                                    />
                                                    <CustomInput
                                                        col="div"
                                                        labelName={store.dispatch({type:'translate', 'text': 'passport_file'})}
                                                        name="passport_file"
                                                        type="file"
                                                        accept="image/jpg,image/png,image/jpeg"
                                                        capture
                                                        exists={store.state.registration.passport_path}
                                                        handleChange={(file, imgTemp) => setFieldValue('passport_file', file)}
                                                        isValid={touched.passport_file && !errors.passport_file}
                                                        isInvalid={!!errors.passport_file}
                                                        error={ (typeof errors.passport_file === 'object') ? store.dispatch({type:'translate', 'text': errors.passport_file.type}).toString().replace('$param$',errors.passport_file.param).replace('$param2$',errors.passport_file.param2) : store.dispatch({type:'translate', 'text': errors.passport_file})}
                                                    />
                                                </Col>
                                            </Row>
                                            </Container>
                                        </Fragment>
                                        <div className={cx('row', styleRegis['wrap-btn'])}>
                                            <div className="container-btn col-md-12 text-center">
                                                <div className='button-control'>
                                                    <ButtonCustom class="yellow-btn" value={store.dispatch({type:'translate', 'text': 'btn_prev'})} onButtonClick={(value) => store.dispatch({type:'clickButton',field:'prev'})} />
                                                    <ButtonCustom id="card-information-btn" class="blue-btn" value={store.dispatch({type:'translate', 'text': 'btn_next'})} type="submit" loading={isSubmitting} disabled={isSubmitting}/>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                    <AlertModal show={this.state.showModal} setShowModal={this.setShowModal}/>
                                </Fragment>
                                )}
                            </Formik>
                        )
                    }
                }
            </RootContext.Consumer>
		)
	}
}